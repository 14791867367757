<header class="h-19.5 mx-4 flex justify-between items-center">
  <div class="left flex gap-2 items-baseline">
    <div class="org-infos text-xl font-bold pr-12">Lagardère</div>
    <div class="flex px-8 gap-12">
      <a [routerLink]="['dashboard']" translate>HEADER.DASHBOARD</a>
      <a [routerLink]="['stats']" translate>HEADER.STATISTICS</a>
      <a [routerLink]="['monitoring']" [class.active]="isActive('monitoring')" translate
        >HEADER.MONITORING</a
      >
      <a
        [routerLink]="['brands']"
        [class.active]="isActive(['brands', 'members', 'settings'])"
        translate
        >HEADER.ORGANIZATION</a
      >
      <a [routerLink]="['logs']">Logs</a>
    </div>
  </div>
  <div class="p-3">
    <rkb-button (click)="switchLanguage()" text="FR <-> EN"></rkb-button>
  </div>
  <div class="right flex items-center leading-7 font-bold">James</div>
</header>
