import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DrawerState } from '../../states/drawer.state';
import { DrawerContainerComponent } from '../drawer-container/drawer-container.component';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'rkb-shell',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, DrawerContainerComponent],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent {
  drawerOpen$: Observable<boolean> = inject(Store).select(DrawerState.drawerIsOpen);
}
