import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormFieldComponent } from "../form-field/form-field.component";
import { SvgIconComponent } from "../svg-icon/svg-icon.component";

@Component({
  selector: 'rkb-input-password',
  standalone: true,
  imports: [CommonModule, FormFieldComponent, SvgIconComponent],
  templateUrl: './input-password.component.html',
  styleUrl: './input-password.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPasswordComponent {
  @Input()
  value: string;

  $show = signal(false);

  toggleVisibility() {
    this.$show.set(!this.$show());
  }
}
