import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateFormatJsCompiler } from 'ngx-translate-formatjs-compiler';

import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { provideStore } from '@ngxs/store';
import { routes } from './app.routes';
import { bearerInterceptor } from './http/bearer.interceptor';
import { BrandState } from './pages/brand/states/brand.state';
import { OrgState } from './pages/org/states/org.state';
import { ProgramState } from './pages/program/states/program.state';
import { AuthState } from './pages/user/state/auth.state';
import { DrawerState } from './shell/states/drawer.state';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { SourceState } from './pages/brand/states/source.state';
import { InputState } from './pages/program/states/inputs.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideHttpClient(withInterceptors([bearerInterceptor])),
    provideAnimationsAsync(),
    provideStore(
      [
        DrawerState,
        AuthState,
        OrgState,
        BrandState,
        SourceState,
        ProgramState,
        InputState,
      ],
      withNgxsStoragePlugin({ keys: ['token'] }),
    ),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        compiler: {
          provide: TranslateCompiler,
          useClass: TranslateFormatJsCompiler,
        },
        loader: {
          provide: TranslateLoader,
          useClass: TranslateHttpLoader,
          deps: [HttpClient],
        },
      }),
    ),
    importProvidersFrom(
      GoogleTagManagerModule.forRoot({
        id: environment.gtm,
      }),
    ),
  ],
};
