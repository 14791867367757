import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { WrapperDirective } from '../../directives/drawer.directive';
import { DrawerState } from '../../states/drawer.state';
import { DynamicDrawer, DynamicItem } from '../../states/drawer.types';

@Component({
  selector: 'rkb-drawer-container',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, WrapperDirective],
  templateUrl: './drawer-container.component.html',
  styleUrl: './drawer-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerContainerComponent implements OnInit {
  @ViewChild(WrapperDirective) rkbWrapper: WrapperDirective;

  currentDrawer$: Observable<DynamicItem> = inject(Store).select(
    DrawerState.currentDrawer,
  );

  $opened = signal(false);

  ngOnInit(): void {
    this.currentDrawer$.subscribe(drawer => {
      if (drawer && drawer.component) {
        this.loadDrawer(drawer);
      }
      this.$opened.set(!!(drawer && drawer.component))
    });
  }

  loadDrawer(drawer: DynamicItem) {
    if (this.rkbWrapper) {
      const viewContainerRef = this.rkbWrapper.viewContainerRef;
      viewContainerRef.clear();
      viewContainerRef.createComponent<DynamicDrawer>(drawer.component);
    }
  }
}
