import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

export interface AuthStateModel {
  isLoggingIn: boolean;
  user: {
    id: string;
    firstname: string;
    lastname: string;
  }
}

const defaultUser: AuthStateModel = {
  isLoggingIn: false,
  user: null,
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: defaultUser
})
@Injectable()
export class AuthState {
  public baseUrl = '';
}
