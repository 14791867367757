<div class="p-6 space-y-8">
  <!-- Toggle Component -->
  <section class="w-[600px]">
    <h2 class="text-xl font-semibold mb-4">Toggle Component</h2>
    <rkb-toggle
      [labels]="toggleLabels"
      [selectedValue]="toggleSelectedValue"
      (selectionChange)="onToggleSelectionChange($event)"
    ></rkb-toggle>
    <p class="mt-2 text-green-600">{{ toggleSelectionMessage }}</p>
  </section>

  <!-- Multiselect Dropdown Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Multiselect Dropdown</h2>
    <rkb-input-dropdown-multiselect
      [options]="propositions"
      (selectedItemsChange)="onSelectedItemsChange($event)"
    ></rkb-input-dropdown-multiselect>
    <p class="mt-2 text-green-600">Selected Items: {{ selectedItemsMulti | json }}</p>
  </section>

  <!-- Search Dropdown Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Input Dropdown</h2>
    <rkb-input-dropdown
      [options]="propositions"
      [searchControl]="searchFormControl"
    ></rkb-input-dropdown>
  </section>

  <!-- Input Field -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Input</h2>
    <rkb-form-field>
      <input type="text" placeholder="Quel bel input" />
    </rkb-form-field>
  </section>

  <!-- Large Input Field -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Large Input (.large-input class)</h2>
    <rkb-form-field>
      <input
        type="text"
        class="large-input"
        placeholder="Mais qu'il est grand cet input"
      />
    </rkb-form-field>
  </section>

  <!-- Primary Button -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Primary Button</h2>
    <rkb-button variant="primary" text="Primary Button"></rkb-button>
  </section>

  <!-- Secondary Button -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Secondary Button</h2>
    <rkb-button variant="secondary" text="Secondary Button"></rkb-button>
  </section>

  <!-- Disabled Button -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Disabled Button</h2>
    <rkb-button variant="primary" text="Disabled Button" [disabled]="true"></rkb-button>
  </section>

  <!-- Loading Button -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Loading Button</h2>
    <rkb-button
      variant="primary"
      text="Loading Button"
      [isLoading]="isLoading"
      (click)="toggleLoading()"
    ></rkb-button>
    <p class="mt-2 text-gray-600">Click the button to toggle loading state.</p>
  </section>

  <!-- Button Click Event -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Button Click Event</h2>
    <rkb-button
      variant="primary"
      text="Click Me"
      (click)="onButtonClick($event)"
    ></rkb-button>
    <p class="mt-2 text-green-600">{{ clickMessage }}</p>
  </section>

  <!-- Split Button with Alternative Actions -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Split Button with Alternative Actions</h2>
    <rkb-button
      text="Add"
      [alternativeActions]="alternativeActionItems"
      (click)="onMainButtonClick()"
    ></rkb-button>
    <p class="mt-2 text-green-600">{{ actionMessage }}</p>
  </section>

  <!-- Switch Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Switch Component</h2>
    <rkb-switch [isChecked]="switchState" (toggle)="onSwitchToggle($event)"></rkb-switch>
    <p class="mt-2 text-green-600">{{ switchToggleMessage }}</p>
  </section>

  <!-- Disabled Switch Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Disabled Switch Component</h2>
    <rkb-switch [isChecked]="disabledSwitchState" [isDisabled]="true"></rkb-switch>
    <p class="mt-2 text-gray-600">This switch is disabled.</p>
  </section>

  <!-- Page Title Component -->
  <section>
    <rkb-page-title>{{ 'ORG.SIDE.BRAND' | translate }}</rkb-page-title>
  </section>

  <!-- Tag Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Tag Component</h2>
    <div class="flex gap-2">
      <rkb-tag>Success</rkb-tag>
      <rkb-tag [type]="tagTypes.info">Info</rkb-tag>
      <rkb-tag [type]="tagTypes.danger" [dot]="true">Danger Dot</rkb-tag>
      <rkb-tag [type]="tagTypes.warning">Warning</rkb-tag>
      <rkb-tag [type]="tagTypes.grey">Grey Dot</rkb-tag>
      <rkb-tag [type]="tagTypes.purple">Source</rkb-tag>
      <rkb-tag [type]="tagTypes.aqua">Program</rkb-tag>
      <rkb-tag [type]="tagTypes.pink">Pink</rkb-tag>
      <rkb-tag [type]="tagTypes.apple">Apple</rkb-tag>
      <rkb-tag [type]="tagTypes.green">Green</rkb-tag>
      <rkb-tag [type]="tagTypes.blue">Blue</rkb-tag>
      <rkb-tag [type]="tagTypes.brown">Brown</rkb-tag>
    </div>
  </section>

  <!-- Input Copy Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Input Copy Component</h2>
    <rkb-input-copy [value]="'Copy me'"></rkb-input-copy>
  </section>

  <!-- Chip Component -->
  <section class="w-[600px]">
    <h2 class="text-xl font-semibold mb-4">Chip</h2>
    <rkb-chip label="Je suis un chip" (remove)="onChipRemove()"></rkb-chip>
  </section>

  <!-- Input Password Component -->
  <section>
    <h2 class="text-xl font-semibold mb-4">Input Password Component</h2>
    <rkb-input-password [value]="'Copy me'"></rkb-input-password>
  </section>
</div>
