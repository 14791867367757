<mat-drawer-container [class.opened]="$opened()">
  <ng-content></ng-content>
  <mat-drawer
    #drawer
    mode="side"
    [opened]="$opened()"
    position="end"
  >
    <div class="drawer-content shadow-block overflow-hidden relative">
      <ng-template rkbWrapper></ng-template>
    </div>
  </mat-drawer>
</mat-drawer-container>
