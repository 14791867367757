import { Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  gtmService: GoogleTagManagerService | null = null;

  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
  ) {
    if (environment.gtm) {
      this.gtmService = this.injector.get<GoogleTagManagerService>(
        GoogleTagManagerService,
      );
    }
  }

  pushToDataLayer(tags: object) {
    if (this.gtmService) {
      this.gtmService.pushTag({ event: 'setData', ...tags });
    }
  }

  registerPageChangeEvent() {
    if (this.gtmService) {
      this.router.events.forEach(item => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'pageView',
            pageName: item.url,
          };
          this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }
}
