import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GtmService } from './services/gtm.service';

@Component({
  selector: 'rkb-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'manager';

  constructor(
    private readonly translateService: TranslateService,
    private readonly gtmService: GtmService,
  ) {
    translateService.setDefaultLang('en');
    this.gtmService.registerPageChangeEvent();
  }
}
