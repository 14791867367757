import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { I18nService } from '../../../services/i18n.service';
import { ButtonComponent } from '../../../ui/components/button/button.component';

@Component({
  selector: 'rkb-header',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, ButtonComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(
    private readonly translateService: TranslateService,
    private readonly i18nService: I18nService,
  ) {
    this.i18nService.init();
  }

  isActive(urls: string | string[]): boolean {
    if (typeof urls === 'string') {
      return window.location.href.indexOf(urls) > 0;
    } else {
      let active = false;
      urls.forEach(url => {
        if (window.location.href.indexOf(url) > 0) {
          active = true;
        }
      });
      return active;
    }
  }

  switchLanguage() {
    const current = this.translateService.currentLang;
    this.i18nService.language = current === 'fr-FR' ? 'en-US' : 'fr-FR';
  }
}
