import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { TranslateCompiler } from '@ngx-translate/core';
import { IntlMessageFormat } from 'intl-messageformat';
class TranslateFormatJsCompiler extends TranslateCompiler {
  /** Compiles single translation */
  compile(value, lang) {
    return params => {
      try {
        return new IntlMessageFormat(value, lang).format(params);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return `ERROR in: "${value}"`;
      }
    };
  }
  /** Compile translations object */
  compileTranslations(translations, lang) {
    this.compileRecursively(translations, lang);
    return translations;
  }
  /**
   * Traverse through object and replace translations strings with compile function.
   * @param obj to be traversed
   * @param lang from @ngx-translate
   * @returns translation object with compile functions.
   */
  compileRecursively(obj, lang) {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        obj[key] = this.compile(value, lang);
      } else {
        this.compileRecursively(value, lang);
      }
    });
  }
}
TranslateFormatJsCompiler.ɵfac = /* @__PURE__ */(() => {
  let ɵTranslateFormatJsCompiler_BaseFactory;
  return function TranslateFormatJsCompiler_Factory(__ngFactoryType__) {
    return (ɵTranslateFormatJsCompiler_BaseFactory || (ɵTranslateFormatJsCompiler_BaseFactory = i0.ɵɵgetInheritedFactory(TranslateFormatJsCompiler)))(__ngFactoryType__ || TranslateFormatJsCompiler);
  };
})();
TranslateFormatJsCompiler.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TranslateFormatJsCompiler,
  factory: TranslateFormatJsCompiler.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslateFormatJsCompiler, [{
    type: Injectable
  }], null, null);
})();

/*
 * Public API Surface of lib
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TranslateFormatJsCompiler };
