import { Routes } from '@angular/router';
import { ComponentsPlaygroundComponent } from './pages/components-playground/components/components-playground/components-playground.component';
import { MonitoringComponent } from './pages/monitoring/components/monitoring/monitoring.component';
import { LoginComponent } from './pages/user/components/login/login.component';
import { ShellComponent } from './shell/components/shell/shell.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'components',
    component: ComponentsPlaygroundComponent,
  },
  {
    path: 'org/:orgId',
    component: ShellComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/org/org.routes').then(routes => routes.orgRoutes),
      },
      {
        path: 'brands/:brandId',
        loadChildren: () =>
          import('./pages/brand/brand.routes').then(routes => routes.brandRoutes),
      },
      { path: 'monitoring', component: MonitoringComponent },
    ],
  },
  {
    path: '',
    redirectTo: '/org/0',
    pathMatch: 'full',
  },
];
