import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../../../../ui/components/button/button.component';
import { ChipComponent } from '../../../../ui/components/chip/chip.component';
import { FormFieldComponent } from '../../../../ui/components/form-field/form-field.component';
import { InputCopyComponent } from '../../../../ui/components/input-copy/input-copy.component';
import { InputDropdownMultiselectComponent } from '../../../../ui/components/input-dropdown-multiselect/input-dropdown-multiselect.component';
import { InputDropdownComponent } from '../../../../ui/components/input-dropdown/input-dropdown.component';
import { InputPasswordComponent } from '../../../../ui/components/input-password/input-password.component';
import { PageTitleComponent } from '../../../../ui/components/page-title/page-title.component';
import { SwitchComponent } from '../../../../ui/components/switch/switch.component';
import { TagComponent, TagType } from '../../../../ui/components/tag/tag.component';
import { ToggleComponent } from '../../../../ui/components/toggle/toggle.component';
import { DropdownOption } from '../../../../ui/models/dropdown-option.model';

interface ActionItem {
  text: string;
  action: () => void;
}

@Component({
  selector: 'rkb-components-playground',
  standalone: true,
  imports: [
    ButtonComponent,
    SwitchComponent,
    FormFieldComponent,
    InputDropdownComponent,
    ToggleComponent,
    PageTitleComponent,
    TagComponent,
    TranslateModule,
    InputCopyComponent,
    InputDropdownMultiselectComponent,
    CommonModule,
    ChipComponent,
    InputPasswordComponent,
  ],
  templateUrl: './components-playground.component.html',
})
export class ComponentsPlaygroundComponent {
  clickMessage: string = '';
  actionMessage: string = '';
  isLoading: boolean = false;
  switchState: boolean = false;
  disabledSwitchState: boolean = true;
  switchToggleMessage: string = '';
  searchFormControl = new FormControl();
  selectedItemsMulti: DropdownOption[] = [];
  tagTypes = { TagType }.TagType;

  // Alternative actions for the split button
  alternativeActionItems: ActionItem[] = [
    { text: 'Create', action: () => this.create() },
    { text: 'Import', action: () => this.import() },
  ];

  // Propositions for the search dropdown
  propositions: DropdownOption[] = [
    { value: 'rfm-hit-party', label: 'RFM Hit Party', disabled: false },
    { value: 'rfm-national', label: 'RFM National', disabled: false },
    { value: 'rfm-fichier-backup', label: 'RFM fichier backup', disabled: false },
  ];

  // Labels for the ToggleComponent
  toggleLabels = ['mp3', 'WAV', 'AIFF'];
  toggleSelectedValue = 'mp3';
  toggleSelectionMessage: string;

  ngOnInit() {
    this.searchFormControl.valueChanges.subscribe(value => {
      console.log('New search value:', value);
    });
  }

  onChipRemove() {
    console.log('chip removed');
  }

  // Handle multiselect selection change
  onSelectedItemsChange(selectedItems: DropdownOption[]) {
    this.selectedItemsMulti = selectedItems;
    console.log('Selected Items:', selectedItems);
  }

  // Handle toggle selection change
  onToggleSelectionChange(selected: string) {
    this.toggleSelectedValue = selected;
    this.toggleSelectionMessage = `Selected: ${selected}`;
  }

  // Main button click handler
  onMainButtonClick() {
    this.actionMessage = 'Main "Add" action executed!';
  }

  // Alternative action handlers
  create() {
    this.actionMessage = 'Alternative "Create" action executed!';
  }

  import() {
    this.actionMessage = 'Alternative "Import" action executed!';
  }

  // Other existing methods
  onButtonClick(event: Event) {
    this.clickMessage = 'Button clicked!';
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  onSwitchToggle(isChecked: boolean) {
    this.switchState = isChecked;
    this.switchToggleMessage = `Switch is now ${isChecked ? 'ON' : 'OFF'}`;
  }
}
